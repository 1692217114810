<template>
  <section class="videoActivity container">
    <div class="videoActivity__cardContainer">
      <ELearningYoutubeCard v-for="(mineItem, index) in videoFilterLists" :key="index" :item="mineItem"/>

      <div style="margin: 6rem auto 10rem auto; width: 100%; text-align: center;">

        <div class="center">
          <paginate
            align="center"
            size="lg"
            :page-count="Math.ceil(videoLists.length/pageSize)"
            :click-handler="onCurrentPageChange"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-class="'page-item'"
            :next-class="'page-item'"
            :prev-link-class="'page-link'"
            :next-link-class="'page-link'"
            :active-class="'active'"
          >
          </paginate>
        </div>
      </div>

      <hr class="videoActivity__hr" />
    </div>

  </section>
</template>

<script>
import ELearningYoutubeCard from '@/components/ELearning/ELearningYoutubeCard.vue'
import BasePagination from '@/components/BasePagination.vue'

// Api
import { apiGetMedia } from '@/api/webAPI'
import $ from "jquery";

export default {
  components: {
    ELearningYoutubeCard,
    BasePagination
  },
  data() {
    return {
      videoLists: [],
      currentPage: 1,
      pageSize: 6
    }
  },
  async created() {
    const mediaResponse = await apiGetMedia('event')
    this.videoLists = mediaResponse.data.data
    this.findPageByTitle()
  },
  computed: {
    videoFilterLists() {
      // page 1 => 0, 6
      // page 2 => 6, 12
      const pageSize = this.pageSize
      const startIndex = pageSize * (this.currentPage - 1)
      const endIndex = pageSize * this.currentPage
      return this.videoLists.slice(startIndex, endIndex)
    }
  },
  methods: {
    onCurrentPageChange(page) {
      this.currentPage = page
    },
    findPageByTitle() {
      if (this.videoLists.length === 0) return
      const title = this.$route.query.title

      if (!title) return
      const videoIndex = this.videoLists.findIndex(
        video => video.title === title
      )

      if (videoIndex === -1) return
      const page = Math.ceil((videoIndex + 1) / 6)

      this.currentPage = page
    }
  },mounted(){
    $('ul.pagination >:first-child').attr('title', '上一頁')
    $('ul.pagination >:last-child').attr('title', '下一頁')
    // $("button").removeAttr('tabindex');
  }
}
</script>

<style lang="scss">
.center{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.videoActivity {
  padding: 4.9rem 0;
  overflow: hidden;

  &__cardContainer {
    display: flex;
    flex-wrap: wrap;
    margin: -1.8rem;
  }

  &__hr {
    border: none;
    border-bottom: 1px dashed #d0a805;
    width: 100%;
    margin: 1.2rem 0 10rem 0;
  }
}

@media (max-width: 768px) {
  .videoActivity {
    padding: 4.9rem 2rem;
  }
}
</style>
